import React from "react"

export const Footer = () => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex flex-col justify-center md:order-2">
          <p className="items-center block text-base leading-6 text-center text-gray-400 sm:flex">
            <svg
              className="hidden w-4 h-4 mr-2 sm:block"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
            </svg>
            <a className="font-bold text-gray-500" href="tel:+18134287535">
              813-428-7535
            </a>
          </p>
          <p className="flex items-center text-base leading-6 text-center text-gray-400">
            <svg
              className="hidden w-4 h-4 mr-2 sm:block"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
            <span className="w-full">
              <a
                href="https://goo.gl/maps/c2XR6k9g9Ds2se8k9"
                rel="noopener noreferrer"
                target="_blank"
              >
                401 East Jackson Street, Suite 2340,{" "}
                <br className="sm:hidden" />
                Tampa, FL 33602
              </a>
            </span>
          </p>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-base leading-6 text-center text-gray-400">
            &copy; 2020 Rudin Legal P.A. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}
