import React from "react"
import { Link } from "gatsby"

import logo from "../../images/logo.png"
import Transition from "../helpers/Transition"

const Header = props => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <>
      <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/">
                <img className="w-auto h-28 sm:h-24" src={logo} alt="" />
              </Link>
              <div className="flex items-center -mr-2 md:hidden">
                <button
                  type="button"
                  onClick={() => setMenuOpen(true)}
                  className="absolute inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md top-3 right-1 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                >
                  <svg
                    className="w-6 h-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:ml-10 md:pr-4">
            <Link
              to="/"
              activeClassName="text-secondary-600 hover:text-secondary-700"
              className="font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              Home
            </Link>
            <Link
              to="/practices/"
              activeClassName="text-secondary-600 hover:text-secondary-700"
              className="ml-8 font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              Areas of Practice
            </Link>
            <Link
              to="/about/"
              activeClassName="text-secondary-600 hover:text-secondary-700"
              className="ml-8 font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              About
            </Link>
            <Link
              to="/contact/"
              activeClassName="text-secondary-600 hover:text-secondary-700"
              className="ml-8 font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              Contact
            </Link>
            <a
              className="px-4 py-4 ml-8 font-bold text-white transition duration-150 ease-in-out border-2 border-transparent rounded bg-secondary-600 hover:bg-secondary-500 focus:outline-none focus:text-secondary-600"
              href="tel:8134287535"
            >
              (813) 428-7535
            </a>
          </div>
        </nav>
      </div>

      <Transition
        show={menuOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute inset-x-0 top-0 z-50 p-2 transition origin-top-right transform md:hidden">
          <div className="rounded-lg shadow-md">
            <div className="overflow-hidden bg-white rounded-lg shadow-xs">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img className="w-auto h-16" src={logo} alt="" />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => setMenuOpen(false)}
                    className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                  >
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                <Link
                  to="/"
                  className="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                >
                  Home
                </Link>
                <Link
                  to="/practices"
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                >
                  Areas of Practice
                </Link>
                <Link
                  to="/about"
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                >
                  About
                </Link>
                <Link
                  to="/contact"
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

export default Header
